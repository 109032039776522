/*******************************************************************************

  Project: kat-theme
  Author:  PROJECT_AUTHOR
  Date:    PROJECT_DATE

********************************************************************************

TOC:
  Fix "Does not use passive listeners to improve scrolling performance" issue in the PageSpeed Insights

  jQuery document ready start
  Scroll to top
  Off canvas menu
  Header
  Dropdown Menu
  Lightbox for images and videos
  Responsive Videos
  Scoll Animations Init
  Object Fit Images
  Contact Form 7 Fix
  Map
  Equal Heights
  Footer fix
  Fix group widths
  AJAX Load More
  Fix 100vh
  jQuery document ready end

*******************************************************************************/

// Fix "Does not use passive listeners to improve scrolling performance" issue in the PageSpeed Insights

jQuery.event.special.touchstart = {
  setup: function (_, ns, handle) {
    this.addEventListener("touchstart", handle, {
      passive: !ns.includes("noPreventDefault"),
    });
  },
};

jQuery.event.special.touchmove = {
  setup: function (_, ns, handle) {
    this.addEventListener("touchmove", handle, {
      passive: !ns.includes("noPreventDefault"),
    });
  },
};

jQuery(document).ready(function ($) {
  // Useful global variables
  var $adminBar = $("#wpadminbar");
  var adminBarEnabled = $adminBar.length ? true : false;

  /* Scroll to top
------------------------------------------------------------------------------*/

  $(document).on("scroll", function () {
    if ($(window).scrollTop() > 200) {
      $(".c-scroll-to-top").addClass("show");
    } else {
      $(".c-scroll-to-top").removeClass("show");
    }
  });

  $(".c-scroll-to-top").on("click", function (event) {
    event.preventDefault();
    var verticalOffset =
      typeof verticalOffset != "undefined" ? verticalOffset : 0;
    var element = $("body");
    var offset = element.offset();
    var offsetTop = offset.top;
    $("html, body").animate({ scrollTop: offsetTop }, 500, "linear");
  });

  /* Mobile Menu Accordion
------------------------------------------------------------------------------*/

  $("ul.c-mobile-menu li").has("ul").addClass("parentLi");
  $("ul.c-mobile-menu li ul").addClass("sub-menu");

  $("ul.c-mobile-menu .sub-menu").hide();

  $("ul.c-mobile-menu .parentLi > a").on("click", function (event) {
    event.preventDefault();
    $(this).toggleClass("expanded");
    $(this).parent().find("ul.sub-menu").slideToggle();
  });

  /* Header
------------------------------------------------------------------------------*/

  // Function which controls header status according to the distance from top
  function setHeaderStatus() {
    const header = document.querySelector(".js-header");
    let lastScroll = 0;

    window.addEventListener("scroll", () => {
      const currentScroll = window.scrollY;

      if (currentScroll <= 0) {
        header.classList.remove("js-header--scrolled");
      } else if (
        currentScroll < lastScroll &&
        header.classList.contains("js-header--scrolled")
      ) {
        header.classList.remove("js-header--scrolled");
      } else if (
        currentScroll > lastScroll &&
        !header.classList.contains("hidden")
      ) {
        header.classList.add("js-header--scrolled");
      }

      lastScroll = currentScroll;
    });
    // if (scroll >= 1) { $('.js-header').addClass('js-header--scrolled'); }
    // else {
    //   if(!($('body').hasClass('hc-nav-open'))) {
    //     $('.js-header').removeClass('js-header--scrolled');
    //   }
    // }
  }

  // Set status on load
  setHeaderStatus();

  // https://codingreflections.com/hide-header-on-scroll-down/
  var doc = document.documentElement;
  var w = window;

  var prevScroll = w.scrollY || doc.scrollTop;
  var curScroll;
  var direction = 0;
  var prevDirection = 0;

  var header = document.querySelector(".js-header");

  var checkScroll = function () {
    /*
     ** Find the direction of scroll
     ** 0 - initial, 1 - up, 2 - down
     */

    curScroll = w.scrollY || doc.scrollTop;
    if (curScroll > prevScroll) {
      //scrolled up
      direction = 2;
    } else if (curScroll < prevScroll) {
      //scrolled down
      direction = 1;
    }

    if (direction !== prevDirection) {
      toggleHeader(direction, curScroll);
    }

    prevScroll = curScroll;
  };

  var toggleHeader = function (direction, curScroll) {
    if (direction === 2 && curScroll > 52) {
      //replace 52 with the height of your header in px

      // header.classList.add('-translate-y-full');
      prevDirection = direction;
    } else if (direction === 1) {
      // header.classList.remove('-translate-y-full');
      prevDirection = direction;
    }
  };

  if (typeof header != "undefined" && header != null) {
    window.addEventListener("scroll", checkScroll);
  }

  /* Dropdown Menu
------------------------------------------------------------------------------*/

  if ($(".c-hor-menu").length) {
    $(".c-hor-menu .menu-item-has-children > a").on("click", function (e) {
      e.preventDefault();
      if (!$(this).parent().hasClass("opened")) {
        $(this).closest(".sub-menu").find(".menu-item").removeClass("opened");
        $(this).parent().addClass("opened");
      } else {
        $(this).parent().removeClass("opened");
      }
    });
  }

  // Hide dropdown after click outside the menu
  $(document).on("click", function (event) {
    if (
      !$(event.target).closest(".c-hor-menu .menu-item-has-children a").length
    ) {
      $(".c-hor-menu .menu-item-has-children").removeClass("opened");
    }
  });

  /* Lightbox for images and videos
------------------------------------------------------------------------------*/

  $(
    "a[href$=jpg], a[href$=jpeg], a[href$=jpe], a[href$=png], a[href$=gif]"
  ).magnificPopup({
    type: "image",
    removalDelay: 500,
    mainClass: "mfp-with-fade",
    closeOnContentClick: true,
    midClick: true,
  });

  $(".popup-youtube, .popup-vimeo, .popup-gmaps").magnificPopup({
    disableOn: 700,
    type: "iframe",
    mainClass: "mfp-with-fade",
    removalDelay: 160,
    preloader: false,
    fixedContentPos: false,
    iframe: {
      patterns: {
        youtube: {
          index: "youtube.com/",
          id: function (url) {
            var m = url.match(/[\\?\\&]v=([^\\?\\&]+)/);
            if (!m || !m[1]) return null;
            return m[1];
          },
          src: "//www.youtube.com/embed/%id%?autoplay=1",
        },
        youtube_short: {
          index: "youtu.be/",
          id: "youtu.be/",
          src: "//www.youtube.com/embed/%id%?autoplay=1", // URL that will be set as a source for iframe.
        },
      },
    },
  });

  /* 9. Contact Form 7 Fix
/*******************************************************************************/

  $(".wpcf7-form p:empty").remove();

  /* Map
/*******************************************************************************/

  $.each($(".dyn-map"), function () {
    var lat = $(this).data("lat");
    var long = $(this).data("long");
    var zoom = $(this).data("zoom");

    L.Icon.Default.imagePath =
      theme_settings.template_dir + "/assets/img/leaflet/";
    var mymap = new L.map($(this)[0], {
      scrollWheelZoom: false,
    }).setView([lat, long], 16);

    // create custom icon
    var pinIcon = L.icon({
      iconUrl: theme_settings.template_dir + "/assets/img/leaflet/pin.svg",
      shadowUrl:
        theme_settings.template_dir + "/assets/img/leaflet/marker-shadow.png",
      iconSize: [40, 50], // size of the icon
      iconAnchor: [16, 50],
      shadowAnchor: [10, 39],
      popupAnchor: [5, -48],
    });

    // Open Street Map
    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      attribution:
        '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      maxZoom: zoom,
    }).addTo(mymap);

    if ($(this).data("popup")) {
      L.marker([lat, long], { icon: pinIcon })
        .addTo(mymap)
        .bindPopup($(this).data("popup"))
        .openPopup();
    }

    // Open Street Map - Wikimedia
    // L.tileLayer('https://maps.wikimedia.org/osm-intl/{z}/{x}/{y}{r}.png', {
    //     attribution: '<a href="https://wikimediafoundation.org/wiki/Maps_Terms_of_Use">Wikimedia</a>',
    //     maxZoom: zoom
    // }).addTo(mymap);

    // Open Street Map - Black and White
    /*
    L.tileLayer('http://{s}.tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
        maxZoom: zoom
    }).addTo(mymap);
    */

    // Mapbox - remember to change accessToken
    /*
    L.tileLayer('https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token={accessToken}', {
        attribution: 'Map data © <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
        maxZoom: zoom,
        id: 'mapbox.streets',
        accessToken: 'pk.eyJ1IjoiYWRhbXBpbGNoIiwiYSI6ImNqbTY0aGlzeTB0eTEza280dGVpcjVnemMifQ.zZjr5GSWWpe4lYGBjvuz1A'
    }).addTo(mymap);
    */

    mymap.on("click", function () {
      if (mymap.scrollWheelZoom.enabled()) {
        mymap.scrollWheelZoom.disable();
      } else {
        mymap.scrollWheelZoom.enable();
      }
    });
  });

  /* Footer fix
------------------------------------------------------------------------------*/

  function fixFooter() {
    if ($("body").outerHeight() < $(window).outerHeight()) {
      var footerHeight = $(".js-footer").outerHeight();
      var bodyHeight = $("body").outerHeight();
      var windowHeight = $(window).outerHeight();
      var contentHeight = bodyHeight - footerHeight;
      $(".js-footer").css("min-height", windowHeight - contentHeight + "px");
    }
  }

  fixFooter();
  $(window).on("debouncedresize", function (event) {
    fixFooter();
  });

  /* Fix group widths
------------------------------------------------------------------------------*/

  $(".wp-block-group").each(function (index) {
    if ($(this).hasClass("alignfull")) {
      $(this).wrap('<div class="wp-block-group-wrap alignfull"></div>');
    } else if ($(this).hasClass("alignwide")) {
      $(this).wrap('<div class="wp-block-group-wrap alignwide"></div>');
    } else {
      $(this).wrap('<div class="wp-block-group-wrap"></div>');
    }
  });

  /* AJAX Load More
------------------------------------------------------------------------------*/

  $(".js-load-more").click(function () {
    var button = $(this);
    var content_place = $(this).parent();
    var data = {
      action: "ajax_load_more",
      query: theme_settings.posts,
      page: theme_settings.current_page,
    };

    if (
      !(
        button.hasClass("c-btn--post-loading-loader") ||
        button.hasClass("c-btn--post-no-more-posts")
      )
    ) {
      $.ajax({
        url: theme_settings.ajaxurl,
        data: data,
        type: "POST",
        beforeSend: function (xhr) {
          button
            .addClass("c-btn--post-loading-loader")
            .find("span")
            .html(theme_settings.loading);
        },
        success: function (data) {
          var tempScrollTop = $(window).scrollTop();
          if (data.length) {
            button
              .removeClass("c-btn--post-loading-loader")
              .find("span")
              .html(theme_settings.loadmore);
            $(data).insertBefore(content_place);
            theme_settings.current_page++;

            if (theme_settings.current_page == theme_settings.max_page)
              button
                .removeClass("c-btn--post-loading-loader")
                .addClass("c-btn--post-no-more-posts")
                .find("span")
                .html(theme_settings.noposts);
          } else {
            button
              .removeClass("c-btn--post-loading-loader")
              .addClass("c-btn--post-no-more-posts")
              .find("span")
              .html(theme_settings.noposts);
          }
          $(window).scrollTop(tempScrollTop);
        },
      });
    }
  });

  /* Fix 100vh
------------------------------------------------------------------------------*/

  var customViewportCorrectionVariable = "vh";

  function setViewportProperty(doc) {
    var prevClientHeight;
    var customVar = "--" + (customViewportCorrectionVariable || "vh");
    function handleResize() {
      var clientHeight = doc.clientHeight;
      if (clientHeight === prevClientHeight) return;
      requestAnimationFrame(function updateViewportHeight() {
        doc.style.setProperty(customVar, clientHeight * 0.01 + "px");
        prevClientHeight = clientHeight;
      });
    }
    handleResize();
    return handleResize;
  }
  window.addEventListener(
    "resize",
    setViewportProperty(document.documentElement)
  );

  /* Popups
------------------------------------------------------------------------------*/

  $('a[href^="#popup-"]').magnificPopup({
    type: "inline",
    midClick: true,
    removalDelay: 900,
    callbacks: {
      beforeOpen: function () {
        this.st.mainClass = this.st.el.attr("data-effect");
      },
    },
  });
}); // end of jQuery code

document.addEventListener("DOMContentLoaded", function () {
  if (document.cookie.indexOf("accepted_cookies=") < 0) {
    const cookieFormWrapper = document.querySelector(".cookie-form-wrapper");
    cookieFormWrapper.classList.remove("d-none");
    cookieFormWrapper.classList.add("d-block");
  }

  document.querySelector(".cookie-btn").addEventListener("click", function () {
    document.cookie = "accepted_cookies=yes;";
    const cookieFormWrapper = document.querySelector(".cookie-form-wrapper");
    cookieFormWrapper.classList.remove("d-block");
    cookieFormWrapper.classList.add("d-none");
  });

  document.querySelectorAll(".button-pref").forEach(function (button) {
    button.addEventListener("click", function () {
      const cookieFormHide = document.querySelectorAll(".cookie-form-hide");
      const cookieFormWrapper = document.querySelector(".cookie-form-wrapper");
      const button = document.querySelector(".button-pref");

      cookieFormHide.forEach((elem) => {
        if (elem.classList.contains("d-none")) {
          cookieFormWrapper.classList.toggle("open-block");
          elem.classList.toggle("d-none");
          button.innerHTML = "Discard changes";
        } else {
          cookieFormWrapper.classList.toggle("open-block");
          elem.classList.toggle("d-none");
          button.innerHTML = "Preferences";
        }
      });
    });
  });

  // footer scroll top animation
  let scrollElement = document.querySelector("footer .scroll-arrow");
  if (scrollElement) {
    scrollElement.addEventListener("click", function () {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    });
  }
});

let count = 0;
const box = document.getElementById("progress");
const container = document.getElementById("preloader");
const inbox = document.getElementById("inner-progress");
const preloaderBg = document.querySelectorAll("#preloader-bg div");

if (box) {
  function startLoader() {
    box.innerHTML = count + "%";
    nextLoader();
  }
  function nextLoader() {
    inbox.style.width = count * 2 + "px";
    if (count < 100) {
      count++;
      setTimeout(startLoader, 10);
    } else {
      preloaderBg.forEach((bg, index) => {
        gsap.fromTo(
          bg,
          {
            duration: 0.1,
            top: "0",
            delay: index / 10,
          },
          {
            duration: 0.1,
            top: "-100%",
            delay: index / 10,
          }
        );
      });
      setTimeout(() => {
        container.style.display = "none";
      }, 500);
    }
  }
  window.addEventListener("load", function () {
    if (document.cookie.indexOf("preloader=") < 0) {
      document.cookie = "preloader=yes;";
      startLoader();
    }
  });

  window.addEventListener("beforeunload", function () {
    document.cookie =
      "preloader=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  });
}
